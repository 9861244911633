@mixin ace-editor($theme) {
    .ace_editor {
        border: 1px solid lightgray;
        border: 1px solid map-get($theme, "border-color");
        height: 100% !important;

        .ace_scrollbar-h,
        .ace_scrollbar-v {
            &::-webkit-scrollbar {
                width: 16px;
            }
            &::-webkit-scrollbar-track {
                background: map-get($theme, "scrollbar-bg");
                background: map-get($theme, "background");
            }
            &::-webkit-scrollbar-thumb {
                background: map-get($theme, "scrollbar-thumb");
            }
            // &::-webkit-scrollbar-thumb:hover {
            //     background: map-get($theme, "scrollbar-thumb-hover");
            // }
        }

        .ace_active-line {
            background: map-get($theme, "ace-active-line");
        }
    }

    .ace_marker {
        background-color: map-get($theme, "highlight-color") !important;
        color: black;
        border: 1px solid #e4e4e4;
        position: absolute;
        z-index: 9999;
    }

    .ace_editor-scroll-xy {
        &::after {
            content: "";
            width: 15px;
            height: 15px;
            position: absolute;
            background: map-get($theme, "scrollbar-bg");
            bottom: 0;
            right: 0;
        }
    }
}

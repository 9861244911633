@mixin settings($theme) {
    #settings {
        position: relative;
        margin: 20px 30px;
        height: 100%;
        font-size: 1.2em;

        h2 {
            display: grid;
            grid-template-columns: auto auto;

            #reset-buttons {
                justify-self: end;
                button {
                    font-size: 0.4em;
                    margin-left: 0.5em;
                    margin-bottom: 0.25em;
                }
            }
        }

        hr {
            margin: 10px 0;
        }

        #settings-inner {
            display: grid;
            grid-auto-rows: auto;
            row-gap: 2.5em;
            margin-top: 1.5em;
        }

        .btn {
            // padding: 0px 6px 2px;
            padding: 0 0.45em 0.15em;
            font-size: 0.8em;
        }

        .btn-light {
            background-color: #ddd;
            border-color: map-get($theme, "tips-color") !important;
        }

        .btn-outline-light {
            color: map-get($theme, "tips-color") !important;
            border-color: map-get($theme, "tips-color") !important;

            &:hover,
            &[aria-expanded="true"] {
                color: $dark !important;
                background-color: #ddd;
            }
        }

        .dual-btn-option {
            display: grid;
            // grid-template-columns: 200px auto 1fr;
            grid-template-columns: 1fr auto;
            grid-template-rows: 1.4em;
            margin-bottom: 0.8em;
        }

        .editor-settings {
            display: grid;
            grid-template-columns: calc(50% - 25px) calc(50% - 25px);
            column-gap: 50px;

            & > div {
                width: 100%;
            }

            .btn-group .btn {
                width: 3em;
            }

            .dropdown-toggle {
                min-width: 4.5em;
                text-align: left;

                &::after {
                    float: right;
                    margin-top: 0.7em;
                }
            }
        }

        @media only screen and (max-width: 992px) {
            .editor-settings {
                grid-auto-flow: row;
                grid-template-columns: auto;
                row-gap: 2.5em;
                & > div {
                    width: 100%;
                }
            }
        }

        .dropdown-setting {
            @include grid-columns-auto-1fr;
            grid-template-rows: 1.5em;
            margin-bottom: 10px;

            label {
                justify-self: start;
            }

            div {
                justify-self: end;
            }

            .dropdown .dropdown-menu {
                font-size: 14px;
                padding: 0.75rem 0.75rem;

                .dropdown-item {
                    padding: 0 0.25rem 0.2em;
                    border-radius: 0.25em;
                    margin: 0.25em 0;
                }
            }
        }

        .page-settings {
            display: grid;
            grid-template-columns: auto;

            .btn-group .btn {
                width: 7em;
            }

            // .dual-btn-option {
            //     grid-template-columns: 170px auto 1fr;
            // }
        }

        .search-presets {
            // @include grid-columns-auto-1fr;
            // $preset-columns: 12em 24em 1fr;
            $preset-columns: 172px 1fr 33px;

            .form-label {
                margin-bottom: 0.2em;
            }

            .headings {
                display: grid;
                grid-template-columns: $preset-columns;
                column-gap: 0.5em;
                margin-bottom: 0.25em;
            }

            .invalid-feedback {
                font-size: 0.7em;
            }

            .add-btn,
            .new-btn {
                padding: 1px 0.5em;
                &::before {
                    @include plus-icon;
                }
            }

            .new-btn {
                margin: 0.6em 0 1em;
            }

            .preset {
                display: grid;
                grid-template-columns: $preset-columns;
                column-gap: 0.5em;
                margin-bottom: 0.5em;
                row-gap: 0.25em;

                .actions {
                    grid-column: 2;
                    justify-self: end;
                    grid-column: 1;
                    justify-self: start;
                    margin: 0 0 0.5em;

                    .btn {
                        margin: 0 !important;
                    }

                    .cancel {
                        @include hover-red;
                        margin-left: 0.5em !important;
                    }
                }

                .remove {
                    @include hover-red;

                    // Parent div
                    @at-root div#{&} {
                        height: 100%;
                    }

                    &::after {
                        @include fa-icon;
                        // content: "\f00d";
                        content: "\f2ed";
                        font-weight: normal;
                        display: flex;
                        align-self: center;
                        justify-self: center;
                        padding: 0 0.25em;
                        padding-top: 0.25em;
                    }
                }
            }
        }
    }
}

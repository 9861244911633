@mixin semantics($theme) {
    /*
     * ===========================
     *      SEMANTIC ELEMENTS
     * ===========================
     */
    html,
    body {
        background: map-get($theme, "background");
        box-sizing: border-box;
        // height: 100vh;
        height: 100%;
        width: 100%;
        overflow: auto;
        color: map-get($theme, "primary-text");
        // border: 1px solid transparent;
    }

    body {
        font-size: 14px;
    }

    hr {
        border-color: map-get($theme, "border-color");
    }

    input[type="text"],
    input[type="text"]:focus {
        background: map-get($theme, "background");
        border-color: map-get($theme, "button-border");
        caret-color: map-get($theme, "input-caret-color");
        color: map-get($theme, "input-color");
    }
    input[type="text"]::placeholder {
        color: map-get($theme, "placeholder");
    }
    input[type="text"][readonly] {
        background-color: map-get($theme, "input-disabled-bg");
    }
}

@mixin no-content($theme) {
    .no-content {
        text-align: center;
        width: 100%;
        display: grid;
        grid-template-rows: 1fr;
        align-items: center;
        justify-content: center;
        height: calc(100% - 50px);
        // height: 100%;
        position: absolute;
        // z-index: -1;

        .wrapper {
            background: map-get($theme, "no-content-bg");
            border: 1px solid map-get($theme, "no-content-border");
            border-radius: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 420px;
            width: 420px;
            margin: 0 auto;
            color: map-get($theme, "no-content-color");

            .fas {
                margin-bottom: 20px;
            }

            button {
                margin-top: 20px;
                box-shadow: none;
            }
        }
    }
}

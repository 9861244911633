@mixin tips($theme) {
    #tips {
        margin: 5px 0 -10px;
        display: grid;
        grid-template-columns: 1fr 1fr;

        #full-screen {
            color: map-get($theme, "tips-color-alt");
            justify-self: start;

            kbd {
                background: map-get($theme, "background");
                border: 1px solid map-get($theme, "tips-color");
                color: map-get($theme, "tips-color");
                padding: 2px 3px;
            }
        }

        #color-theme {
            justify-self: center;
            padding: 1px 4px 2px;
            margin-top: 2px;
            font-size: 0.8em;
        }

        #learn-regex {
            justify-self: end;
            a {
                color: map-get($theme, "tips-color");

                &:hover {
                    color: $info;
                }

                span::after {
                    @include fa-icon;
                    content: "\f35d";
                    margin-left: 0.5rem;
                }
            }
        }

        .btn-outline-light {
            color: map-get($theme, "tips-color") !important;
            border-color: map-get($theme, "tips-color") !important;

            &:hover {
                color: $dark !important;
                background-color: #ddd;
            }
        }
    }
}

@mixin plus-icon {
    font-family: "Font Awesome 5 Free";
    font-weight: bold;
    content: "\f067";
    margin-right: 0.5em;
}

@mixin grid-columns-auto-1fr($column-gap: 1em, $row-gap: 1em) {
    display: grid;
    grid-template-columns: auto 1fr;
    column-gap: $column-gap;
    row-gap: $row-gap;
}

@mixin hover-red {
    &:hover {
        background-color: $danger;
        color: white !important;
        border-color: $danger !important;
    }
}

@mixin sweetalert($theme) {
    .swal2-popup {
        background: map-get($theme, "background");

        .swal2-content,
        h2 {
            color: map-get($theme, "primary-text");
        }

        .swal2-content {
            .enable {
                color: var(--success);
            }

            .disable {
                color: var(--danger);
            }

            .review {
                text-align: left;
                display: inline-block;
                margin: 20px auto 0;
                padding: 15px 40px 20px;
                border-radius: 10px;
                border: 2px solid lightgray;
            }
        }
    }
}

@mixin simple-styles($theme) {
    // Prevents default auto-fill styles
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        box-shadow: 0 0 0 1000px map-get($theme, "background") inset !important;
        -webkit-text-fill-color: map-get($theme, "primary-text") !important;
        transition: background-color 5000s ease-in-out 0s; // Prevents flash when auto-filling
    }

    .badge {
        padding: 5px 15px;
    }

    .blue {
        color: map-get($theme, "primary");
    }

    .blue-outline {
        color: map-get($theme, "primary") !important;
        border: 1px solid map-get($theme, "primary");
    }

    .br-none {
        border-right: none !important;
    }

    .capitalize {
        text-transform: capitalize;
    }

    .courier {
        font-family: monospace;
    }

    .dark-outline {
        color: $dark;
        border: 1px solid $dark;
    }

    .dropdown-item:hover,
    .dropdown-item:focus {
        background: none;
        color: map-get($theme, "primary") !important;
    }

    .hov-blue:hover {
        color: map-get($theme, "primary") !important;
    }

    .hov-dark:hover {
        color: $dark !important;
    }

    .hvr-underline:hover {
        text-decoration: underline;
    }

    .info-bg {
        color: white;
        background-color: lighten($info, 4);
        background-color: $info;
    }

    .info-outline {
        color: $info;
        border: 1px solid $info;
    }

    .input-error-msg {
        font-style: italic;
        font-size: 12px;
        color: orangered;
    }

    .is-invalid {
        padding-right: calc(0.5em + 1px) !important;
        border-color: $danger !important;
    }

    .monospace {
        font-family: Consolas, "Courier New", monospace;
        // font-size: 87.5%;
    }

    .no-select {
        -webkit-user-select: none; /* Safari */
        user-select: none;
    }

    .popover-header {
        color: black;
    }

    .primary-bg {
        background: map-get($theme, "primary");
    }

    .ptr:hover {
        cursor: pointer;
    }

    .refresh {
        // background: map-get($theme, "primary") !important;
        // border-color: map-get($theme, "primary") !important;
        &::after {
            @include fa-icon;
            content: "\f2f1";
        }
    }

    .rot-90 {
        transform: rotate(90deg) translateY(0.5px);
    }

    .rot-180 {
        transform: rotate(180deg);
    }

    .rot-n180 {
        transform: rotate(-180deg);
    }

    .theme-bg {
        background: map-get($theme, "background");
    }

    .tr-fast {
        transition: 0.1s linear;
    }

    .txt-ctr {
        text-align: center;
    }

    .underline {
        text-decoration: underline;
    }
}

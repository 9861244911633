@import "node_modules/bootstrap/scss/bootstrap.scss";
@import url("https://fonts.googleapis.com/css2?family=Caesar+Dressing&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Sonsie+One&display=swap");
@import "style";

html,
html[theme="light"] {
    $theme: (
        "ace-active-line": #ddecff,
        "alt-text": gray,
        "background": white,
        "border-color": #d3d3d3,
        "button": #5b5b5b,
        "button-border": #ced4da,
        "button-color": #6c757d,
        "button-color-active": white,
        "card-hover-bg": #dadada,
        "danger-alert-background": #f8d7da,
        "danger-alert-border": #f5c6cb,
        "danger-alert-text": #721c24,
        "draggable-divider": #838383,
        "draggable-divider-grips": lightgray,
        "dropdown-shadow": 2px 4px 10px #bbbb,
        "highlight-color": #ffff00,
        "input-color": $dark,
        "input-caret-color": $secondary,
        "input-disabled-bg": #e9ecef,
        "lightgray": #bababa,
        "match-found": #28a745,
        "nav": $dark,
        "nav-active-bg": #878787,
        "nav-active-text": white,
        "no-content-bg": #f3f3f3,
        "no-content-border": lightgray,
        "no-content-color": $dark,
        "no-results": #6c757d,
        "placeholder": #909090,
        "primary": #1a77ba,
        "primary-text": $dark,
        "scrollbar-bg": #efefef,
        "scrollbar-thumb": #c6c6c6,
        "scrollbar-thumb-hover": #888,
        "tabs-active-bg": #f8f8f8,
        "tabs-active-text": $dark,
        "tips-color": $dark,
        "tips-color-alt": #6c757d,
    );
    @include mainStyleFromMap($theme);
}

html,
html[theme="dark"] {
    $theme: (
        "ace-active-line": #4d5965,
        "alt-text": lightgray,
        "background": #323232,
        "border-color": #747474,
        "button": #5b5b5b,
        "button-border": #5b5d5e,
        "button-color": whitesmoke,
        "button-color-active": whitesmoke,
        "card-hover-bg": #575757,
        "danger-alert-background": #663a3c,
        "danger-alert-border": #9f464d,
        "danger-alert-text": #ffc0c0,
        "draggable-divider": #7f7f7f,
        "draggable-divider-grips": #3f3f3f,
        "dropdown-shadow": 2px 4px 10px #000b,
        "highlight-color": #0086ff,
        // "input-color": #d3d3d3,
        "input-color": #f8f9fa,
        "input-border-color": #d3d3d3,
        "input-caret-color": #d3d3d3,
        "input-disabled-bg": #6d6d6d,
        "lightgray": #bababa,
        "match-found": #27d04e,
        "nav": #252525,
        "nav-active-bg": #c5c5c5,
        "nav-active-text": black,
        "no-content-bg": $dark,
        "no-content-border": lightgray,
        "no-content-color": #f3f3f3,
        "no-results": #c0c0c0,
        "placeholder": #aaaaaa,
        "primary": #73a9cf,
        "primary-text": whitesmoke,
        "scrollbar-bg": #292929,
        "scrollbar-thumb": #3b3b3b,
        "scrollbar-thumb-hover": #272727,
        "tabs-active-bg": #444,
        "tabs-active-text": whitesmoke,
        "tips-color": #cbcbcb,
        "tips-color-alt": #a3a3a4,
    );
    @include mainStyleFromMap($theme);
}

@mixin editor-group($theme) {
    #editor-group {
        display: grid;
        grid-template-rows: auto 1fr;

        .matches {
            color: map-get($theme, "match-found");
        }
        .no-results {
            color: map-get($theme, "no-results");
        }

        // Column Wrapper
        .gPDMsR {
            max-width: 100%;
            margin-right: -100px; // Keeps divider from going out of bounds
            border-radius: 0.25em;
            min-height: 450px;
        }
        // Row wrapper
        .ZaWQW {
            max-height: 100%;
            margin-bottom: -100px; // Keeps divider from going out of bounds
            border-radius: 0.25em;
            min-height: 450px;
        }
        // Column
        .elusdB {
            max-width: 99%;
        }
        // Row
        .dUUMQs {
            max-height: calc(100% - 10px);
        }
        // Divider that allows for resizing
        .sc-bdVaJa {
            background-color: gray;
            background-color: map-get($theme, "draggable-divider");
            text-align: center;
            display: flex;
            align-items: center;
            align-content: center;
            justify-items: center;
            justify-content: center;
        }

        // Vertical bar
        .hqAieZ::after {
            @include fa-icon;
            content: "\f142";
            content: "\f7a5";
            color: map-get($theme, "draggable-divider-grips");
        }

        // Horizontal bar
        .fCAxyQ::after {
            @include fa-icon;
            content: "\f141";
            content: "\f7a4";
            color: map-get($theme, "draggable-divider-grips");
        }
    }

    #no-data {
        display: flex;
        align-self: center;
        justify-self: center;

        color: map-get($theme, "primary-text");
        // border: 1px solid map-get($theme, "primary-text");
        border-radius: 10em;
        padding: 10px 20px;

        p {
            margin-bottom: 0.1em;
            font-size: 1.1em;
        }
    }
}

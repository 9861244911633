@mixin editor-controls($theme) {
    .clear-search {
        border-left: none;
        border-right: none;
        border-radius: 0;
        padding-right: 1.25em;
        display: inline-block;

        &::after {
            @include fa-icon;
            content: "\f00d";
            font-size: 0.8em;
            position: absolute;
            display: flex;
            align-self: center;
        }

        &:hover {
            background: map-get($theme, "background") !important;
        }
    }

    .code-fold-buttons {
        color: map-get($theme, "primary-text");
    }

    .dropdown .dropdown-menu {
        font-size: 14px;
        padding: 0.75rem 0.75rem;
        min-width: initial;
        background-color: map-get($theme, "background");
        box-shadow: map-get($theme, "dropdown-shadow");

        a {
            color: map-get($theme, "primary-text");
        }

        .dropdown-item {
            padding: 0.25rem 0.5rem;
            border-radius: 0.25em;

            &:hover {
                background: none;
                color: map-get($theme, "primary");
            }
        }
        .dropdown-divider {
            border-color: map-get($theme, "border-color");
        }
    }

    #editor-group .no-select {
        // height: 1.6em;
        padding-top: 1px;
    }

    .btn:focus,
    .dropdown:focus,
    .form-control:focus,
    .custom-select:focus {
        box-shadow: none !important;
    }

    .editor-controls {
        .btn {
            border-color: map-get($theme, "button-border") !important;
            color: map-get($theme, "button-color");

            &:hover,
            &[aria-expanded="true"] {
                background: map-get($theme, "button");
                color: map-get($theme, "button-color-active");
            }
        }
    }

    .match-iterator-buttons {
        margin: 0 1.2em;
        display: inline-flex;
        margin-top: -3px;
        margin-bottom: -3px;
        span {
            padding: 0 0.5em;
            font-size: 1.5em;
            position: relative;
            top: 3px;
        }
        // .caret-up::after {
        //     @include fa-icon;
        //     content: "\f0d8";
        // }
        // .caret-down::after {
        //     @include fa-icon;
        //     content: "\f0d7";
        // }
    }

    .quicksave-form .dropdown-divider {
        margin-top: 15px;
    }

    .search {
        padding-right: 0;
    }

    .search-quicksave {
        padding: 0.4em 0.5em;
        height: unset;
        font-size: 1em;
        width: 18em;
    }
}

@mixin info-panel($theme) {
    #info-panel {
        // color: map-get($theme, "primary-text");

        b {
            color: map-get($theme, "primary");
            &::after {
                content: " ";
            }
        }

        #info-toggle {
            font-size: 1.2rem;
        }

        #status {
            margin-left: 10px;
            padding: 5px 6px 4px;
            text-transform: uppercase;
        }

        #tz-dropdown {
            position: relative;
            margin-left: 0.4em;
            padding: 1px 5px;
            font-size: 0.9em;
        }

        #tz-span {
            display: inline-block;

            .dropdown {
                padding-right: 0;
            }

            .dropdown .dropdown-menu {
                font-size: 14px;
                padding: 0.75rem 0.75rem;
                min-width: initial;
                background-color: map-get($theme, "background");
                box-shadow: map-get($theme, "dropdown-shadow");
                border: none;

                a {
                    color: map-get($theme, "primary-text");
                }

                .dropdown-item {
                    padding: 0.25rem 0.5rem;
                    border-radius: 0.25em;

                    &:hover {
                        background: none;
                        color: map-get($theme, "primary");
                    }
                }
                .dropdown-divider {
                    border-color: map-get($theme, "border-color");
                }
            }
        }

        .grid-tri-column {
            display: inline-grid;
            grid-template-columns: auto auto auto;

            & > div {
                padding: 0 20px;
            }

            & > div:nth-child(1) {
                padding-left: 0;
            }

            & > div:nth-child(2) {
                border: 1px solid map-get($theme, "border-color");
                border-top: none;
                border-bottom: none;
            }
        }
    }
}

@mixin navbar($theme) {
    #search-container-form {
        width: 100%;
        display: grid;
        grid-template-columns: auto 70px;
        grid-auto-flow: column;
        column-gap: 16px;

        #search-bar {
            border-color: map-get($theme, "button-border") !important;
            width: 100%;
            box-sizing: border-box;
            margin: 0 !important;
            &::placeholder {
                font-size: 14px;
            }
        }

        #search-bar,
        .btn {
            padding: 0px 6px 2px;
            height: unset;
            font-size: 14px;
        }

        #search-button {
            color: darken($light, 10%);
            border-color: darken($light, 10%) !important;
            &:hover {
                background: darken($light, 10%);
                border-color: darken($light, 10%);
                color: $dark;
            }
        }

        .search-controls {
            position: relative;
            .dropdown-toggle {
                background: map-get($theme, "background");
                border-color: map-get($theme, "button-border") !important;
                color: map-get($theme, "button-color");
                font-size: 0.9em;
                padding-right: 0.75em;
                display: grid;
                align-content: center;
                grid-auto-flow: column;
                position: relative;

                &::after {
                    content: ":";
                    border: none;
                }

                &:hover,
                &[aria-expanded="true"] {
                    background: map-get($theme, "button");
                    border-color: map-get($theme, "button") !important;
                    color: map-get($theme, "button-color-active");
                }
            }
        }

        .clear-id {
            border-color: map-get($theme, "button-border") !important;
            color: map-get($theme, "button-color");
            background: map-get($theme, "background");
            border-left: none;
            padding: 0 0.75em;

            &::after {
                @include fa-icon;
                content: "\f00d";
                font-size: 0.8em;
                display: flex;
                align-self: center;
            }

            &:hover {
                background: map-get($theme, "background");
            }
        }
    }

    .dropdown-menu > .loading {
        position: relative;
        width: 100%;
        margin-top: 1em;
    }

    .nested-nav {
        .nav-link {
            color: map-get($theme, "primary-text");
        }
    }

    .navbar {
        height: 40px;
        background: map-get($theme, "nav");

        display: grid;
        grid-template-columns: auto 1fr auto;
        column-gap: 20px;
        padding: 0.25rem 0.5rem;

        .nav-link {
            &:hover {
                color: white;
            }
        }

        .right-align-menu .dropdown-menu {
            right: 0;
            left: unset;
        }
    }

    .navbar,
    .nested-nav {
        button {
            // padding: 0 0.6em !important;
            background: none;
            border: none;
            outline: none;
            color: #ffffff80;

            &:active {
                background: none;
            }
            &:hover {
                color: white;
            }
            &::after {
                font-size: 1.25em;
            }
        }

        .nav-link {
            padding: 0.25rem 1rem 0.3rem;

            &.active {
                background: map-get($theme, "nav-active-bg");
                color: map-get($theme, "nav-active-text");
            }
        }
    }

    // .navbar > .buttons {
    //     display: grid;
    //     grid-template-columns: auto;
    //     grid-auto-flow: column;
    // }

    #home-button {
        margin-top: 2px;
        &::after {
            @include fa-icon;
            content: "\f015";
            content: "\f0c9";
        }
    }

    #acd-modal-button::after {
        @include fa-icon;
        content: "\f542";
    }

    #environment-dropdown,
    #more-options {
        &[aria-expanded="true"] {
            background: map-get($theme, "nav-active-bg");
            color: map-get($theme, "nav-active-text");
        }

        & + .dropdown-menu > .dropdown-header {
            margin: 0;
            padding: 0.5rem 0.5rem 0;
        }
    }

    #environment-dropdown {
        &[aria-expanded="true"] span {
            color: map-get($theme, "nav-active-text") !important;
        }
        margin-right: 15px;
        text-align: center;
        &::after {
            border: none;
            display: none;
            margin: none;
        }
    }

    #more-options {
        &::after {
            @include fa-icon;
            content: "\f142"; // ellipsis-v
            border: none;
        }
    }
}

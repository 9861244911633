@mixin view-mode($theme) {
    #vm-wrapper {
        display: flex;
        justify-items: center;
        justify-content: center;
        // padding-top: 32px;
        // padding-top: 5px;
        display: grid;
        grid-template-rows: auto 1fr;
        row-gap: 0.5em;
        // border: 1px solid map-get($theme, "border-color");

        #search-link {
            font-size: 0.9em;

            &.chain::after {
                @include fa-icon;
                content: "\f0c1";
            }

            &.chain-broken::after {
                @include fa-icon;
                content: "\f127";
            }
        }

        #view-mode {
            // border: 1px solid map-get($theme, "border-color");
            font-size: 1.5em;
            &::after {
                @include fa-icon;
                content: "\f58e";
            }
        }

        #search-link,
        #view-mode {
            color: map-get($theme, "lightgray");
            border-radius: 0.25em;
            margin: 0 8px;
            height: 25px;
            width: 25px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}

@mixin landing-page($theme) {
    .landing-cards {
        margin: 5em;
        display: grid;
        grid-template-columns: repeat(2, minmax(max-content, 25em));
        height: min-content;
        grid-gap: 5em;
        justify-self: center;

        .card {
            color: map-get($theme, "primary-text");
            background: map-get($theme, "background");
            box-shadow: map-get($theme, "dropdown-shadow");
            border: 1px solid map-get($theme, "border-color");
            border-radius: 15px;
            text-align: center;
            cursor: pointer;

            &:hover {
                background: map-get($theme, "card-hover-bg");
            }

            .card-img-top::after {
                font-size: 10em;
            }

            .address-book::after {
                @include fa-icon;
                content: "\f2b9";
            }

            .credit-card::after {
                @include fa-icon;
                content: "\f09d";
            }
        }
    }
}

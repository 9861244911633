@import "node_modules/bootstrap/scss/bootstrap.scss";
@import url("https://fonts.googleapis.com/css2?family=Caesar+Dressing&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Sonsie+One&display=swap");
@import "mixins/ace-editor";
@import "mixins/editor-controls";
@import "mixins/editor-group";
@import "mixins/info-panel";
@import "mixins/landing-page";
@import "mixins/list-item";
@import "mixins/misc-classes";
@import "mixins/misc-mixins";
@import "mixins/modals";
@import "mixins/navbar";
@import "mixins/no-content";
@import "mixins/semantics";
@import "mixins/settings";
@import "mixins/simple-styles";
@import "mixins/sweetalert";
@import "mixins/tips";
@import "mixins/view-mode";

@mixin fa-icon {
    font-family: "Font Awesome 5 Free";
    font-weight: bold;
}

@mixin mainStyleFromMap($theme) {
    @include semantics($theme);

    /*
     * ========================
     *      ELEMENTS BY ID
     * ========================
     */
    #brand {
        font-family: "Sonsie One";
        text-transform: capitalize;
    }

    #content {
        position: relative;
        margin: 20px 30px;
        display: grid;
        grid-template-rows: auto 1fr 25px;

        /* 
         * "-30px" necessary for keeping tips inside body on transaction page.
         */
        // height: calc(100% - 30px);
        height: calc(100% - 80px);
    }

    #root {
        /*
         * "-2px" necessary for preventing scrollbar from appearing without
         * enough content to scroll.
         */
        height: calc(100vh - 2px);
        // display: grid;
        // grid-template-rows: auto 1fr;
        overflow: auto;
        overflow-y: scroll;

        /* width */
        &::-webkit-scrollbar {
            width: 15px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
            background: #f1f1f1;
            background: map-get($theme, "background");
            background: map-get($map: $theme, $key: "scrollbar-bg");
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: #888;
            background: lighten(map-get($theme, "background"), 10%);
            background: map-get($map: $theme, $key: "scrollbar-thumb");
        }
    }

    @include navbar($theme);

    @include editor-group($theme);

    @include info-panel($theme);

    @include settings($theme);

    @include tips($theme);

    @include view-mode($theme);

    /*
    * ===========================
    *      ELEMENTS BY CLASS
    * ===========================
    */
    @include ace-editor($theme);

    @include editor-controls($theme);

    @include landing-page($theme);

    @include list-item($theme);

    @include misc-classes($theme);

    @include modals($theme);

    @include no-content($theme);

    @include simple-styles($theme);

    @include sweetalert($theme);
}

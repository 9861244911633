@mixin modals($theme) {
    #acd-modal,
    #settings-modal {
        justify-content: center;

        .modal-content {
            display: inline-block;
            width: auto;
        }
    }

    #acd-modal {
        b {
            color: map-get($theme, "primary");
        }

        h3 {
            text-transform: capitalize;
            margin-bottom: 0.75em;
        }

        hr {
            width: calc(100% + 2rem);
            margin-left: -1rem;
            margin-top: 2em;
        }

        .acd-readonly > span {
            display: block;
        }
        .acd-readonly-grid {
            display: grid;
            grid-template-columns: 1fr 1fr;
            column-gap: 1em;
            white-space: nowrap;
        }

        .acd-readonly,
        .acd-readonly-grid {
            span {
                margin-bottom: 1em;
            }
        }

        .actions,
        .actions-with-back {
            display: grid;
            grid-template-columns: auto auto;
            justify-content: end;
            column-gap: 1em;
        }

        .actions-with-back {
            grid-template-columns: 1fr auto auto;
        }
    }

    .modal-body {
        min-width: 30em;
        position: relative;

        .loading {
            position: relative;
            margin-top: 1em;
        }
    }

    .modal-content {
        background: map-get($theme, "background");

        .close {
            color: map-get($theme, "primary-text");
        }

        .modal-header,
        .modal-footer {
            border-color: map-get($theme, "border-color");
        }
    }
}

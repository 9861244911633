@mixin custom-scroll {
    &::-webkit-scrollbar {
        width: 10px;
        height: 10px;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: #c2c9d2;
    }
    &::-webkit-scrollbar-corner {
        background: transparent;
    }
}

@mixin misc-classes($theme) {
    $border-color: map-get($theme, "input-border-color");

    .alert {
        & .close {
            display: flex;
            align-content: center;
            justify-content: center;
            outline: none;
            height: 100%;
            & > span {
                margin-top: -0.15em;
            }
        }
    }

    .alert-danger {
        @if (map-get($theme, "danger-alert-background")) {
            background-color: map-get($theme, "danger-alert-background");
            border-color: map-get($theme, "danger-alert-border");
            color: map-get($theme, "danger-alert-text");
        }
    }

    .alert-success .close {
        color: $dark !important;
    }

    .account-details {
        display: grid;
        grid-auto-flow: column;
        // grid-template-columns: auto minmax(300px, max-content);
        grid-template-columns: auto 300px;
        column-gap: 2em;

        // column-gap: 20px;
    }

    @media only screen and (max-width: 850px) {
        .account-details {
            grid-auto-flow: row;
            grid-template-columns: 1fr;
        }
    }
    // button.add-new {
    //     &::after {
    //         @include fa-icon;
    //         content: "\f0fe";
    //         font-weight: normal;
    //     }
    // }

    .breadcrumb {
        margin: 0;
        padding: 0;
        background: none;

        a {
            color: map-get($theme, "primary-text");
        }

        li.active {
            color: map-get($theme, "alt-text");
        }
    }

    .btn-secondary:focus {
        background: $secondary;
    }

    .caret-up::after {
        @include fa-icon;
        content: "\f0d8";
    }
    .caret-down::after {
        @include fa-icon;
        content: "\f0d7";
    }

    .center-content {
        width: 100%;
        padding: 20px 5em;
        display: grid;
        justify-items: center;
        align-content: start;
        row-gap: 20px;

        & > * {
            width: 100%;
            max-width: 60em;
            max-width: 960px;
        }
    }

    .content-header-actions {
        display: grid;
        grid-auto-flow: column;
        column-gap: 20px;
        align-items: center;
    }

    // .Collapsible {
    //     width: 100%;
    //     margin-bottom: 40px;
    //     // padding-right: 4px;

    //     .Collapsible__trigger {
    //         display: block;
    //         width: 100%;
    //         padding-bottom: 5px;
    //         cursor: pointer;
    //         border-bottom: 1px solid map-get($theme, "border-color");
    //     }
    // }

    .custom-scroll {
        @include custom-scroll;
    }
    .custom-scroll-sm {
        @include custom-scroll;
        &::-webkit-scrollbar {
            width: 6px;
            height: 6px;
        }
    }

    .grid-bi-column {
        display: inline-grid;
        grid-template-columns: auto auto;
        column-gap: 100px;
    }

    .grid-bi-column-fr {
        display: grid;
        // grid-auto-flow: column;
        grid-template-columns: 1fr 1fr;
        column-gap: 20px;
    }

    .hover-danger {
        @include hover-red;

        // border: 1px solid var(--secondary);
        // color: #dc3545;
        // background: none;
    }

    .labeled-input-wrapper {
        display: grid;
        grid-template-columns: auto 1fr;
        gap: 1em;
        align-items: center;

        &.align-labels-right > label {
            justify-self: end;
        }

        .dropdown-toggle {
            text-align: left;
            width: 100%;

            border-color: $border-color;

            &::after {
                float: right;
                margin-top: 0.7em;
            }
        }

        input[type="text"] {
            border-color: $border-color;
        }

        label {
            margin-bottom: 0;
        }
    }

    .loading {
        color: map-get($theme, "primary-text");
        white-space: nowrap;

        & > div p {
            padding-right: 0.75em;

            &::after {
                position: absolute;
                animation: load 1.5s infinite;
                content: "   ";
            }

            @keyframes load {
                from {
                    content: "   ";
                }
                10% {
                    content: "   ";
                }
                40% {
                    content: ".  ";
                }
                70% {
                    content: ".. ";
                }
                to {
                    content: "...";
                }
            }
        }
    }

    .nav-tabs {
        a {
            color: map-get($theme, "primary-text");
        }
        .active {
            background: map-get($theme, "tabs-active-bg");
            border-bottom: 1px solid #dee2e6;
            color: map-get($theme, "tabs-active-text");
        }
    }

    .regex-flags > button::after {
        display: none !important;
    }

    .viewing {
        color: map-get($theme, "primary-text");
    }

    .widget-underLabel {
        margin: -5px 0 10px;
    }

    // ::-webkit-resizer {
    //     border: 2px solid orange;
    //     transform: rotate(45deg);
    // }
}
